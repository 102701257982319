import { useContext } from 'react';
import { BrowserRouter as Router, Switch, Route, useHistory } from 'react-router-dom';
import { Box } from '@island.is/ui';
import { store } from './store/store';
import { setupAxios } from './axios';
import { PrivateRoute } from './components/PrivateRoute';
import Error from './pages/Error';
import NewCard from './pages/NewCard';
import ChooseCard from './pages/ChooseCard';
import Authenticate from './pages/Authenticate';
import PaymentResult from './pages/PaymentResult';
import PaymentAuthHtml from './pages/PaymentAuthHtml';
import PaymentCallback from './pages/PaymentCallback';

function App() {
  const { dispatch } = useContext(store);
  setupAxios(useHistory(), dispatch);

  return (
    <>
      <Router basename="/quickpay">
        <Box marginTop={[0, 1, 5]}>
          <Switch>
            <Route path='/pay'>
              <Authenticate />
            </Route>
            <PrivateRoute path='/card'>
              <ChooseCard />
            </PrivateRoute>
            <PrivateRoute path='/add'>
              <NewCard />
            </PrivateRoute>
            <Route path='/reentry'>
              <PaymentCallback />
            </Route>
            <PrivateRoute path='/3ds'>
              <PaymentAuthHtml />
            </PrivateRoute>
            <Route path='/result'>
              <PaymentResult />
            </Route>
            <Route path='/error'>
              <Error />
            </Route>
            <Route path='/'>
              <Error />
            </Route>
          </Switch>
        </Box>
      </Router>
    </>
  );
}

export default App;
