import { Box, Button, Table, Text } from '@island.is/ui';
import { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { useAuthService } from '../services/AuthService';
import { PaymentFlow } from '../components/PaymentFlow/PaymentFlow';
import { store } from '../store/store';
import { currencyAmount } from '../components/CurrencyAmount';
import { goToSuccessUrlAfterDelay, goToSuccessUrl, goToReturnUrl } from '../utils';
import { strings } from '../strings';
import { formatDateTime } from '../utils';
import { actions } from '../store/actions';

function PaymentResult() {
  const { resetLocalStorage } = useAuthService();
  const history = useHistory();
  const {
    dispatch,
    state: { global: state },
  } = useContext(store);

  const py = state.payment;
  const success = py?.state === 'processed';

  if (!success) {
    dispatch({ type: actions.ERROR, value: 'PAYMENT_FAILED' });
    history.push('/error');
    return null;
  } else {
    // Success.  Clear any params that were stored.
    resetLocalStorage();
    // goToSuccessUrlAfterDelay();
    goToSuccessUrl();
    return null; // Skip payment result showing receipt number etc.
  }

  const docTypeName = py?.items[0].document.documentType?.description;

  return (
    <>
      <PaymentFlow
        heading2={strings.PAYMENT_SUCCESSFUL}
        heading={
          <Text as='h1'>
            <Box textAlign='center' paddingBottom={1}>
              <Text>{strings.PAYMENT_SUCCESSFUL}</Text>
            </Box>
            <Box textAlign='center' paddingBottom={1}>
              <Text>
                <strong>{docTypeName} ✓</strong>
              </Text>
            </Box>
          </Text>
        }
        subheading={currencyAmount(py.amount)}
        renderButtons={
          <Box textAlign='center' paddingY={2}>
            <Button fluid onClick={goToSuccessUrl}>
              {strings.BACK}
            </Button>
          </Box>
        }
      >
        <Box
          marginBottom={2}
          background='white'
          borderWidth='standard'
          borderColor='blue200'
          borderRadius='standard'
          padding={3}
        >
          <Table.Table>
            <Table.Body>
              <Table.Row>
                <Table.Data>
                  <strong>{strings.DOC_TYPE}</strong>
                </Table.Data>
                <Table.Data>{docTypeName}</Table.Data>
              </Table.Row>
              <Table.Row>
                <Table.Data>
                  <strong>{strings.AUTHORIZATION}</strong>
                </Table.Data>
                <Table.Data>{py.authorization}</Table.Data>
              </Table.Row>
              <Table.Row>
                <Table.Data>
                  <strong>{strings.PAYMENT_TIME}</strong>
                </Table.Data>
                <Table.Data>{formatDateTime(py.updateTime)}</Table.Data>
              </Table.Row>
              <Table.Row>
                <Table.Data borderColor='transparent'>
                  <strong>{strings.CONFIRMATION_NUMBER}</strong>
                </Table.Data>
                <Table.Data borderColor='transparent'>{py.confirmation}</Table.Data>
              </Table.Row>
            </Table.Body>
          </Table.Table>
        </Box>
      </PaymentFlow>
    </>
  );
}

export default PaymentResult;
