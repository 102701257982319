import { createContext, useReducer, useMemo } from 'react';
import { initialState as globalState, reducer as globalReducer } from './globalStore';
import combineReducers from 'react-combine-reducers';

const [reducer, initialState] = combineReducers({
  global: [globalReducer, globalState],
});

const store = createContext(initialState);

const StateProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const memoized = useMemo(() => {
    // console.log(JSON.stringify(state));
    return {state, dispatch};
  }, [state]);
  return <store.Provider value={memoized}>{children}</store.Provider>;
};

export { store, StateProvider };