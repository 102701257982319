import { useContext } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useEnvironment } from '../environment';
import { authApi } from './api/AuthApi';
import { documentApi } from './api/DocumentApi';
import { store } from '../store/store';
import { setReturnUrl, getCookie } from '../utils';
import { actions } from '../store/actions';
import { paymentApi } from './api/PaymentApi';

const DOC_ID = 'doc_id';
const DOC_NUM = 'doc_num';
const RETURN_URL = 'returnURL';
const AUTHTOKEN = 'authToken';
const ISS = 'iss';
const LOGIN_HINT = 'login_hint';
const TARGET_LINK_URI = 'target_link_uri';

export const useAuthService = () => {
  const { dispatch } = useContext(store);
  const env = useEnvironment();
  const history = useHistory();
  const location = useLocation().search;
  const query = new URLSearchParams(location);

  const dispatchError = (err) => {
    dispatch({ type: actions.ERROR, value: err });
    history.push('/error');
  };

  const resetLocalStorage = () => {
    localStorage.removeItem(DOC_ID);
    localStorage.removeItem(DOC_NUM);
    localStorage.removeItem(ISS);
    localStorage.removeItem(LOGIN_HINT);
    localStorage.removeItem(AUTHTOKEN);
  };

  // Return the query params expected to be on an auth callback.
  const getAuthParams = (retry) => {
    let authToken = query.get(AUTHTOKEN);
    let docId = query.get(DOC_ID);

    if (!authToken && !docId && retry) {
      authToken = getCookie(env.authTokenCookieName);
      if (!authToken) {
        console.error(`Couldn't get authtoken value from cookie`);
      }
      docId = localStorage.getItem(DOC_ID);
    }

    return { authToken, docId };
  };

  const isOidcCallBack = () => {
    const { authToken, docId } = getAuthParams(false);
    return authToken && docId;
  };

  const initOidc = (retry = false) => {
    dispatch({ type: actions.LOGIN_START });

    if (isOidcCallBack()) {
      return true;
    }

    let docId = query.get(DOC_ID);
    let docNum = query.get(DOC_NUM);
    let iss = query.get(ISS);
    let login_hint = query.get(LOGIN_HINT);
    let target_link_uri = query.get(TARGET_LINK_URI);
    if (retry) {
      if (!docId) docId = localStorage.getItem(DOC_ID);
      if (!docNum) docNum = localStorage.getItem(DOC_NUM);
      if (!iss) iss = localStorage.getItem(ISS);
      if (!login_hint) login_hint = localStorage.getItem(LOGIN_HINT);
    }

    if (!docId && !docNum && !(iss && login_hint && target_link_uri)) {
      console.error(`No ${DOC_ID} or ${DOC_NUM} param was provided; ${ISS}, ${LOGIN_HINT} or {target_link_uri} param was missing`);
      dispatchError('DOC_NOT_SPECIFIED');
      return false;
    }

    if (!docId && !docNum) { // ThirdPartyInitiatedLogin (new)
      if (!target_link_uri.startsWith(window.location.origin + window.location.pathname + '?')) {
        console.error(`${TARGET_LINK_URI} was invalid`);
        dispatchError('DOC_NOT_SPECIFIED');
        return false;
      }

      const target_link_url = new URL(target_link_uri); // Extract query string in target_link_uri
      docId = target_link_url.searchParams.get(DOC_ID); // ${DOC_ID}=<doc_id>
      docNum = target_link_url.searchParams.get(DOC_NUM);// ${DOC_NUM}=<doc_num>
      const returnUrl = target_link_url.searchParams.get(RETURN_URL); // ${RETURN_URL}=<url>

      if (!docId && !docNum) {
        console.error(`No ${DOC_ID} or ${DOC_NUM} param was provided in ${TARGET_LINK_URI}`);
        dispatchError('DOC_NOT_SPECIFIED');
        return false;
      }

      localStorage.setItem(DOC_ID, docId);
      localStorage.setItem(DOC_NUM, docNum);

      iss = encodeURIComponent(iss);
      login_hint = encodeURIComponent(login_hint);
      localStorage.setItem(ISS, iss);
      localStorage.setItem(LOGIN_HINT, login_hint);

      setReturnUrl(returnUrl);

      const url = `${env.api.oidc}?${docId ? DOC_ID : DOC_NUM}=${docId || docNum}&${ISS}=${iss}&${LOGIN_HINT}=${login_hint}`;
      window.location.replace(url);
    } else {
      if (!retry) {
        localStorage.setItem(DOC_ID, docId);
        localStorage.setItem(DOC_NUM, docNum);
        const returnUrl = query.get(RETURN_URL);
        setReturnUrl(returnUrl);
      }

      if (retry && iss && login_hint) { // ThirdPartyInitiatedLogin (retry)
        const url = `${env.api.oidc}?${docId ? DOC_ID : DOC_NUM}=${docId || docNum}&${ISS}=${iss}&${LOGIN_HINT}=${login_hint}`;
        window.location.replace(url);
      } else {
        const url = `${env.api.oidc}?${docId ? DOC_ID : DOC_NUM}=${docId || docNum}`;
        window.location.replace(url);
      }
    }

    return false;
  };

  const initAppState = (retry = false) => {
    const { authToken, docId } = getAuthParams(retry);

    if (!docId) {
      console.error('Doc id not provided after OIDC auth');
      dispatchError('DOC_NOT_FOUND');
      return;
    }

    // Store doc id so it's available if the user retries the payment.
    localStorage.setItem(DOC_ID, docId);

    authApi.login(authToken).then(
      (data) => {
        const la = data.loginAccount;
        dispatch({
          type: actions.LOGIN_COMPLETE,
          value: { authToken: data.authToken, loginAccount: la },
        });

        loadDoc(docId)
          .then((doc) => {
            return setupPayment(doc, la.href).then((py) => {
              return history.push('/card');
            });
          })
          .catch((error) => {
            dispatchError(error.message);
          });
      },
      (err) => {
        // This is an unexpected login failure, because we got
        // an auth token but couldn't log in with it.
        console.error('Login failed', err);
        dispatch({ type: actions.LOGIN_FAILED, value: 'AUTH_FAILED' });
        history.push('/error');
      }
    );
  };

  const loadDoc = (docId) => {
    return documentApi.get(docId).then(
      (doc) => {
        if (doc.invoiceData?.paymentInfo?.paidStatus === 'paid') {
          throw new Error('DOC_PAID');
        } else if (!doc.invoiceData?.paymentInfo?.isPayable) {
          throw new Error('DOC_NOT_PAYABLE');
        }
        dispatch({ type: actions.DOC_LOADED, value: doc });
        return doc;
      },
      (err) => {
        console.error(err);
        throw new Error('DOC_NOT_FOUND');
      }
    );
  };

  const setupPayment = (doc, laId) => {
    const py = buildPayment(doc, laId);
    return paymentApi.feeCalc(py).then(
      (fetched) => {
        const amountOwing = fetched.items[0].amountOwing;
        py.amount = amountOwing;
        py.items[0].amount = amountOwing;
        dispatch({ type: actions.PY_LOADED, value: py });
        return py;
      },
      (err) => {
        console.error(err);
        throw new Error('FEE_CALC_FAILED');
      }
    );
  };

  const buildPayment = (doc, laId) => {
    const paymentMethod = {
      alternateKey: 'credit',
    };
    const amount = doc.invoiceData.paymentInfo.amountOwing;

    return {
      amount: amount,
      items: [
        {
          amount: amount,
          document: {
            href: doc.href,
          },
        },
      ],
      loginAcct: {
        href: laId,
        company: {
          contextKey: 'host',
        },
      },
      paymentMethod: paymentMethod,
    };
  };

  return { initOidc, initAppState, resetLocalStorage };
};
