import { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Box, Button } from '@island.is/ui';
import { CreditCardSelector } from '../components/CreditCardSelector/CreditCardSelector';
import { PaymentFlow, PayHeader } from '../components/PaymentFlow/PaymentFlow';
import { paymentAccountApi } from '../services/api/PaymentAccountApi';
import { currencyAmount } from '../components/CurrencyAmount';
import { usePaymentService } from '../services/PaymentService';
import { store } from '../store/store';
import { goToReturnUrl } from '../utils';
import { strings } from '../strings';
import { actions } from '../store/actions';

function ChooseCard() {
  const { makePayment } = usePaymentService();
  const {
    dispatch,
    state: { global: state },
  } = useContext(store);
  const [inProgress, setInProgress] = useState(false);
  const history = useHistory();
  const handleNewCardClick = () => history.push('/add');

  const handlePayClick = () => {
    setInProgress(true);
    makePayment(null);
  };

  const amountOwing = state.payment.amount;

  useEffect(() => {
    paymentAccountApi.findByOwner(state.loginAccount.href).then(
      (data) => {
        let cards = [];
        data.forEach((pya) => {
          if (!pya.isSingleUse && pya.state === 'active') {
            cards.push({
              href: pya.href,
              cardType: pya.cardNetwork,
              lastFourDigits: (pya.maskedAccount || pya.acctNumber).slice(-4),
              expiry: (pya.expiryMonth + '/' + ('' + pya.expiryYear).slice(-2)),
              paymentAccount: pya,
            });
          }
        });
        // cards = [];  // Simulate no cards for user.
        dispatch({ type: actions.PYA_LOADED, value: cards });
        if (cards.length === 0) {
          history.push('/add');
        }
      },
      (error) => {
        console.error('Payment accounts find error', error);
        throw error;
      }
    );
    // Don't include 'history' because it will cause the component to re-render repeatedly.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, state.loginAccount.href]);

  return (
    <>
      <PaymentFlow
        heading={<PayHeader docType={state.document?.documentType?.description} />}
        subheading={currencyAmount(amountOwing)}
        loading={inProgress}
        renderButtons={
          <Box textAlign='center' paddingY={2}>
            <Button fluid variant='text' onClick={goToReturnUrl}>
              {strings.CANCEL}
            </Button>
          </Box>
        }
      >
        <CreditCardSelector
          availableCards={state.paymentAccounts}
          onNewCard={handleNewCardClick}
          disabled={inProgress}
        />
        <Box paddingY={2}>
          <Button
            fluid
            onClick={handlePayClick}
            loading={inProgress}
            disabled={!state.selectedPaymentAccount || amountOwing === 0}
          >
            {strings.PAY}
          </Button>
        </Box>
      </PaymentFlow>
    </>
  );
}

export default ChooseCard;
