import React from 'react';
import { Box, Button } from '@island.is/ui';

import { CreditCard } from './CreditCard/CreditCard';
import { strings } from '../../strings';

type Card = {
  href: string;
  cardType: 'mastercard' | 'visa';
  lastFourDigits: string;
  expiry: string;
  paymentAccount: { href: string };
};
interface CreditCardSelectorProps {
  onNewCard?: () => void;
  availableCards: Card[];
  disabled: boolean;
}

export const CreditCardSelector: React.FC<CreditCardSelectorProps> = ({
  onNewCard = () => null,
  availableCards,
  disabled = false,
}) => (
  <Box position='relative'>
    {availableCards.map((card) => (
      <CreditCard
        key={card.paymentAccount.href}
        cardType={card.cardType}
        lastFourDigits={card.lastFourDigits}
        expiry={card.expiry}
        href={card.href}
        paymentAccount={card.paymentAccount}
        disabled={disabled}
      />
    ))}
    <Box marginTop={1} marginBottom={4}>
      <Button fluid variant='ghost' onClick={onNewCard} disabled={disabled}>
        {strings.ADD_CARD}
      </Button>
    </Box>
  </Box>
);
