import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { usePaymentService } from '../services/PaymentService';
import { PaymentFlow } from '../components/PaymentFlow/PaymentFlow';
import { strings } from '../strings';

function PaymentCallback() {
  const { handlePaymentCallback } = usePaymentService();
  const location = useLocation().search;

  useEffect(
    () => {
      const query = new URLSearchParams(location);
      handlePaymentCallback(query.get('altkey'));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [] /* Only run on first render */
  );

  return <PaymentFlow loading={true} heading={strings.PROCESSING_PAYMENT} />;
}

export default PaymentCallback;
