export const actions = {
  LOGIN_START: 'login-start',
  LOGIN_FAILED: 'login-failed',
  LOGIN_COMPLETE: 'login-complete',
  LOGOUT: 'logout',
  ERROR: 'error',
  DOC_LOADED: 'doc-loaded',
  PYA_LOADED: 'pya-loaded',
  PYA_SELECTED: 'pya-selected',
  PYA_REMOVED: 'pya-removed',
  PY_LOADED: 'py-loaded',
  AUTH_HTML: 'auth-html',
};
