import { format } from 'date-fns';
import { is } from 'date-fns/locale';

const RETURN_URL = 'returnUrl';

export const setReturnUrl = (url) => {
  if (!url) {
    localStorage.removeItem(RETURN_URL);
  } else {
    localStorage.setItem(RETURN_URL, url);
  }
};
export const goToReturnUrl = () => {
  const url = localStorage.getItem(RETURN_URL);
  if (url) {
    if (url.split('?').length === 1) {
      window.location.replace(url.concat('?cancelled'));
    } else if (url.split('?').length === 2) {
      const query = url.split('?').pop().replaceAll(' ','');
      if (query === 'done') {
        window.location.replace(url.replace('?done', '?cancelled'));
      } else if (query.startsWith('done&')) {
        window.location.replace(url.replace('?done&', '?cancelled&'));
      } else if (query.endsWith('&done')) {
        window.location.replace(url.replace(/&done$/, '&cancelled'));
      } else if (query.includes('&done&')) {
        window.location.replace(url.replaceAll('&done&', '&cancelled&'));
      } else {
        window.location.replace(url.concat('&cancelled'));
      }
    } else {
      console.warn('returnURL format error - back links are disabled');
    }
  } else {
    console.warn('No returnURL was provided - back links are disabled');
  }
};
export const goToSuccessUrl = () => {
  const url = localStorage.getItem(RETURN_URL);
  if (url) {
    if (url.split('?').length === 1) {
      window.location.replace(url.concat('?done'));
    } else if (url.split('?').length === 2) {
      const query = url.split('?').pop().replaceAll(' ','');
      if (query === 'done' || query.startsWith('done&') || query.endsWith('&done') || query.includes('&done&')) {
        window.location.replace(url);
      } else {
        window.location.replace(url.concat('&done'));
      }
    } else {
      console.warn('returnURL format error - back links are disabled');
    }
  } else {
    console.warn('No returnURL was provided - back links are disabled');
  }
};
export const goToSuccessUrlAfterDelay = () => {
  setTimeout(() => { goToSuccessUrl(); }, 2000); // 2s delay for payment result
};

export const getCookie = (name) => {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  return parts.length === 2 ? parts.pop().split(';').shift() : null;
};

export const formatDateTime = (dateTime) => {
  const f = process.env.REACT_APP_DATETIME_FORMAT;
  return format(new Date(), f, { locale: is });
};
