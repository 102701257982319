import { HttpService } from './HttpService';
import { env } from '../../environment';

const uri = env.api.pya;

class PaymentAccountApi extends HttpService {
  async findByOwner(ownerHref) {
    const id = this.getIdFromHref(ownerHref);
    const rql = `and(eq(paymentAcctOwner.loginAcct.href,"${id}"),ne(state,inactive))`;
    return this.doFind(uri, rql).then(
      (response) => {
        return response.data;
      },
      (error) => {
        console.error('PaymentAccount find error', error);
        throw error;
      }
    );
  }

  async create(paymentAccount) {
    return this.doPost(`${uri}`, paymentAccount).then(
      (response) => {
        return response.data;
      },
      (error) => {
        console.error('Payment account create failed', error);
        throw error;
      }
    );
  }

  async delete(paymentAccount) {
    paymentAccount.state = 'inactive';
    return this.doPut(`${uri}${paymentAccount.href}`, paymentAccount).then(
      (response) => {
        return response.data;
      },
      (error) => {
        console.error('Payment account delete failed', error);
        throw error;
      }
    );
  }
}

export const paymentAccountApi = new PaymentAccountApi();
