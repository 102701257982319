import { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { paymentApi } from './api/PaymentApi';
import { store } from '../store/store';
import { actions } from '../store/actions';

const STATE_AUTHORIZED = 'authorized';
const STATE_PROCESSED = 'processed';

export const usePaymentService = () => {
  const {
    dispatch,
    state: { global: state },
  } = useContext(store);
  const history = useHistory();

  const makePayment = (newPaymentAccount = null) => {
    const payment = state.payment;
    payment.paymentAcct = newPaymentAccount || state.selectedPaymentAccount.paymentAccount;
    paymentApi.pay(payment).then(
      (data) => {
        const html = data.html;
        dispatch({ type: actions.AUTH_HTML, value: html });
        history.push('/3ds');
      },
      (e) => {
        handlePaymentFailure(e);
      }
    );
  };

  const handlePaymentCallback = async (altkey) => {
    // Called after any payment is processed (regardless of its state or whether it was
    // with a new or saved card).  Handle the payment differently based on its state:
    // - "authorized": this was a payment with a new card -> call /pyur to move it to processed.
    // - "processed": this was a successful payment with a saved card -> do nothing.
    // - for any other state: this payment is considered failed -> show the payment failure page.
    try {
      let payment = !altkey ? null : await paymentApi.getByAlternateKey(altkey);
      if (!payment) {
        handlePaymentFailure();
        return;
      }
      let state = payment.state;
      if (state === STATE_AUTHORIZED) {
        await paymentApi.updateRecord(payment.alternateKey);
        payment = await paymentApi.get(payment.href);
        state = payment.state;
      }
      dispatch({ type: actions.PY_LOADED, value: payment });
      if (state === STATE_PROCESSED) {
        history.push('/result');
      } else {
        handlePaymentFailure();
      }
    } catch (e) {
      handlePaymentFailure(e);
    }
  };

  const handlePaymentFailure = (e) => {
    console.error('Payment error: ', e);
    dispatch({ type: actions.ERROR, value: e });
    history.push('/result');
  };

  return { makePayment, handlePaymentCallback };
};
