import { Box, Logo, Text, AlertMessage, LoadingIcon } from '@island.is/ui';
import { strings } from '../../strings';
import * as styles from './PaymentFlow.treat';

type Error = {
  heading?: string;
  message?: string;
};

interface PaymentFlowProps {
  error?: Error;
  heading?: React.ReactNode;
  subheading?: string;
  renderButtons?: React.ReactNode;
  loading?: boolean;
}

export const PayHeader = ({ docType }) => (
  <Text as='h1'>
    {strings.PAYMENT_FOR} <strong>{docType}</strong>
  </Text>
);

export const PaymentFlow: React.FC<PaymentFlowProps> = ({
  error,
  heading,
  subheading,
  renderButtons,
  loading = false,
  children,
}) => (
  <Box display='flex' flexDirection='column' justifyContent='center' alignItems='center'>
    <Box
      className={styles.frame}
      background='blueberry100'
      borderWidth='standard'
      borderColor='blueberry200'
      borderRadius='standard'
      paddingY={6}
      paddingX={[4, 6, 8, 12]}
    >
      <Box paddingX={3}>
        <Box display='flex' justifyContent='center' paddingBottom={[4, 6, 8]}>
          {loading ? <LoadingIcon animate size={64} /> : <Logo iconOnly width={64} height={64} />}
        </Box>
      </Box>
      {error && (error.heading || error.message) && (
        <Box paddingY={2}>
          <AlertMessage type='error' title={error.heading} message={error.message} />
        </Box>
      )}
      {heading && (
        <Box textAlign='center' paddingBottom={1}>
          {heading}
        </Box>
      )}
      {subheading && (
        <Box textAlign='center' paddingBottom={4}>
          <Text as='h2' variant='h1'>
            {subheading}
          </Text>
        </Box>
      )}
      {children}
      {renderButtons}
    </Box>
  </Box>
);
