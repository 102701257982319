import cn from 'classnames';

import * as styles from './RemoveButton.treat';

type RemoveButtonProps = {
  onClick?: () => void;
  disabled: boolean;
};

export const RemoveButton: React.FC<RemoveButtonProps> = ({ onClick, disabled }) => (
  <button className={cn(styles.root)} onClick={onClick} disabled={disabled}>
    <span className={styles.icon} />
  </button>
);
