import axios from 'axios';

export abstract class HttpService {
  async doGet(url, expand = null, headers = {}) {
    const config = { withCredentials: true };
    let fullUrl = url;
    if (expand) {
      fullUrl += `?expand=${this.encodeRql(expand)}`;
    }
    return await axios.get(fullUrl, config);
  }

  async doFind(url, rql, expand = null, headers = {}) {
    const config = { withCredentials: true };
    let fullUrl = url;
    if (expand && expand.length > 0) {
      fullUrl += `?expand=${this.encodeRql(expand)}&`;
    } else {
      fullUrl += '?';
    }
    fullUrl += `rql=${this.encodeRql(rql)}`;
    return await axios.get(fullUrl, config);
  }

  async doPost(url, body = {}, headers = {}) {
    const config = { withCredentials: true };
    return await axios.post(url, body, config);
  }

  async doPut(url, body = {}, headers = {}) {
    const config = { withCredentials: true };
    return await axios.put(url, body, config);
  }

  getIdFromHref(href) {
    return href.substring(href.lastIndexOf('/') + 1);
  }

  encodeRql(rawRql) {
    let rql = encodeURIComponent(rawRql);
    rql = rql
      .replace(/!/g, '%21')
      .replace(/'/g, '%27')
      .replace(/\*/g, '%2A')
      .replace(/\(/g, '%28')
      .replace(/\)/g, '%29')
      .replace(/,/g, '%2C')

      .replace(/\[/g, '%5B')
      .replace(/\]/g, '%5D')
      .replace(/,/g, '%2C');

    return rql;
  }
}
