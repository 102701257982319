import axios from 'axios';

const setupAxios = (history, dispatch) => {
  axios.defaults.baseURL = process.env.REACT_APP_API_BASE_URL;
  axios.defaults.timeout = 30000;

  axios.defaults.headers.common['ebpAppKey'] = process.env.REACT_APP_API_CLIENT_APP_NAME;
  axios.defaults.headers.get['Content-Type'] = 'application/json';
  axios.defaults.headers.post['Content-Type'] = 'application/json';
};

export { setupAxios };
