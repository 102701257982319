import { useContext } from 'react';
import { store } from '../store/store';
import InnerHTML from 'dangerously-set-html-content'

function PaymentAuthHtml() {
  const {state: {global: state}} = useContext(store);
  return (
    <InnerHTML html={state.authHtml} />
  );
}

export default PaymentAuthHtml;
