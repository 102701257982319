import { useContext } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { store } from '../store/store';

function PrivateRoute({ children, ...rest }) {
  const {
    state: { global: state },
  } = useContext(store);

  if (state.isLoginInProgress() || state.isLoginFailed()) {
    return null;
  }

  return (
    <Route
      {...rest}
      render={({ location }) =>
        state.isLoggedIn() ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: '/error',
              state: { from: location },
            }}
          />
        )
      }
    />
  );
}

export { PrivateRoute };
