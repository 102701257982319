import { HttpService } from "./HttpService";
import { env } from '../../environment';

const uri = env.api.authToken;

class AuthApi extends HttpService {
  async login(authToken: string) {
    return this.doPost(`${uri}`, { authToken: authToken }).then(
      (response) => {
        return response.data;
      },
      (error) => {
        console.error("Login error", error);
        throw error;
      }
    );
  }
}

export const authApi = new AuthApi();
