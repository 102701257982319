import { createContext, useContext } from 'react';

const env = {
  authTokenCookieName: process.env.REACT_APP_API_AUTH_COOKIE_NAME,

  // REST API
  api: {
    baseUrl: process.env.REACT_APP_API_BASE_URL,
    authToken: process.env.REACT_APP_API_AUTHTOKEN,
    oidc: process.env.REACT_APP_API_OIDC,
    pya: process.env.REACT_APP_API_PAYMENT_ACCOUNT,
    doc: process.env.REACT_APP_API_DOCUMENT,
    py: process.env.REACT_APP_API_PAYMENT,
    pync: process.env.REACT_APP_API_PAYMENT_NEW_CARD,
    pysc: process.env.REACT_APP_API_PAYMENT_SAVED_CARD,
    pyur: process.env.REACT_APP_API_PAYMENT_UPDATE_RECORD,
    feeCalc: process.env.REACT_APP_API_PAYMENT_FEECALC,
  },

  // Used for local dev/debugging only.
  defaultPaymentAmount: process.env.REACT_APP_DEFAULT_PAYMENT_AMOUNT || 0,
  defaultCardNumber: process.env.REACT_APP_DEFAULT_CARD_NUMBER || '',
};

console.info('REST API base URL: ', env.api.baseUrl);
console.debug('Environment:', env);

const EnvironmentContext = createContext();
const useEnvironment = () => useContext(EnvironmentContext).state;

export { env, EnvironmentContext, useEnvironment };
