import cn from 'classnames';
import { Box, DialogPrompt, Text } from '@island.is/ui';
import * as styles from './CreditCard.treat';
import { MasterCardIcon } from '../icons/MasterCard';
import { VisaIcon } from '../icons/Visa';
import { RemoveButton } from '../RemoveButton/RemoveButton';
import { store } from '../../../store/store';
import { useContext } from 'react';
import { paymentAccountApi } from '../../../services/api/PaymentAccountApi';
import { actions } from '../../../store/actions';
import { strings } from '../../../strings';

type ValidCard = 'mastercard' | 'visa';

interface CreditCardProps {
  href: string;
  cardType: ValidCard;
  lastFourDigits: string;
  expiry: string;
  active?: boolean;
  disabled?: boolean;
  paymentAccount: object;
}

type RadioProps = {
  cardType?: string;
  id?: string;
  value?: string | number;
  checked?: boolean;
  disabled?: boolean;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
};

type ConfirmProps = {
  trigger: React.ReactElement;
  onConfirm?: () => void;
};

const SelectionRadio: React.FC<RadioProps> = ({ onChange, disabled, checked, value, id }) => (
  <>
    <input
      className={styles.input}
      type='radio'
      disabled={disabled}
      id={id}
      onChange={onChange}
      value={value}
      checked={checked}
    />
    <label
      className={cn(styles.label, {
        [styles.labelChecked]: checked,
        [styles.radioButtonLabelDisabled]: disabled,
      })}
      htmlFor={id}
    >
      <div
        className={cn(styles.radioButton, {
          [styles.radioButtonChecked]: checked,
          [styles.radioButtonDisabled]: disabled,
        })}
      >
        <div className={styles.checkMark} />
      </div>
    </label>
  </>
);

const ConfirmModal: React.FC<ConfirmProps> = ({ trigger, onConfirm }) => (
  <>
    <DialogPrompt
      baseId='remove-card-modal'
      title={strings.REMOVE_CARD}
      description=''
      ariaLabel={strings.REMOVE_CARD}
      disclosureElement={trigger}
      onConfirm={onConfirm}
      buttonTextConfirm={strings.REMOVE_CARD_CONFIRM}
      buttonTextCancel={strings.REMOVE_CARD_CANCEL}
    />
  </>
);

const getIcon = (cardType: ValidCard) => {
  return cardType === 'mastercard' ? MasterCardIcon : VisaIcon;
};

const MaskedNumbers = ({ total = 4 }) => {
  return (
    <Box component='span' className={styles.maskedNumbers}>
      {Array.from({ length: total }, () => '*')}
    </Box>
  );
};

export const CreditCard: React.FC<CreditCardProps> = ({
  cardType = 'mastercard',
  lastFourDigits,
  expiry,
  disabled = false,
  href,
  paymentAccount,
}) => {
  const {
    dispatch,
    state: { global: state },
  } = useContext(store);
  const onChange = () => dispatch({ type: actions.PYA_SELECTED, value: href });
  const onRemove = () => removeCard(paymentAccount);

  const removeCard = (paymentAccount) => {
    paymentAccountApi.delete(paymentAccount).then(
      (data) => {
        dispatch({ type: actions.PYA_REMOVED, value: href });
      },
      (e) => {
        console.error('Error deleting payment account', e);
        dispatch({ type: actions.ERROR, value: e });
      }
    );
  };

  const active = state.selectedPaymentAccount?.href === href;
  const Icon = getIcon(cardType);

  return (
    <div className={styles.root}>
      <SelectionRadio
        onChange={onChange}
        disabled={disabled}
        checked={active}
        value={cardType}
        id={href}
      />
      <Box
        background='white'
        borderColor={active ? 'blue400' : 'blue200'}
        borderWidth='standard'
        borderRadius='large'
        onClick={onChange}
        marginY={1}
        paddingY={1}
        paddingX={2}
        display='flex'
        alignItems='center'
        flexGrow={1}
        marginX={2}
      >
        <Box marginRight={[2, 4, 5]}>
          <Icon />
        </Box>
        <Box display='flex' flexDirection='column' marginRight={[2, 4, 5]}>
          <Text fontWeight={active ? 'semiBold' : 'regular'} variant='small'>
            {cardType === 'mastercard' ? strings.MASTERCARD : strings.VISA}
          </Text>
          <Text fontWeight={active ? 'semiBold' : 'regular'} variant='small'>
            <MaskedNumbers /> <MaskedNumbers /> <MaskedNumbers /> {lastFourDigits}
          </Text>
        </Box>
        <Box display='flex' flexDirection='column'>
          <Text fontWeight={active ? 'semiBold' : 'regular'} variant='small'>
            {strings.EXPIRY}
          </Text>
          <Text fontWeight={active ? 'semiBold' : 'regular'} variant='small'>
            {expiry}
          </Text>
        </Box>
      </Box>
      <ConfirmModal trigger={<RemoveButton disabled={disabled} />} onConfirm={onRemove} />
    </div>
  );
};
