import { actions } from './actions';

const LoginStatus = {
  NotLoggedIn: 0,
  InProgress: 1,
  LoggedIn: 2,
  Failed: 3,
};

const initialState = {
  error: null,
  loginStatus: LoginStatus.NotLoggedIn,
  loginAccount: null,
  document: null,
  paymentAccounts: [],
  selectedPaymentAccount: null,
  payment: null,
  authHtml: null,

  isLoggedIn: function () {
    return this.loginStatus === LoginStatus.LoggedIn;
  },
  isLoginInProgress: function () {
    return this.loginStatus === LoginStatus.InProgress;
  },
  isLoginFailed: function () {
    return this.loginStatus === LoginStatus.Failed;
  },
};

const reducer = (state, action) => {
  switch (action.type) {
    case actions.LOGIN_START:
      return { ...state, loginStatus: LoginStatus.InProgress };
    case actions.LOGIN_FAILED:
      const error = action?.value?.message;
      return {
        ...state,
        loginStatus: LoginStatus.Failed,
        error: error || 'AUTH_FAILED',
      };
    case actions.LOGIN_COMPLETE:
      return {
        ...state,
        loginStatus: LoginStatus.LoggedIn,
        authToken: action.value.authToken,
        loginAccount: action.value.loginAccount,
      };
    case actions.ERROR:
      // Action value may be an Error object or a string.
      // If it's an Error, store the error's message.
      const msg = action.value?.message || action.value || 'DEFAULT_ERROR';
      return { ...state, error: msg };
    case actions.LOGOUT:
      return { ...state, ...initialState };

    case actions.DOC_LOADED:
      return { ...state, document: action.value };

    case actions.PYA_LOADED:
      return { ...state, paymentAccounts: action.value };
    case actions.PYA_SELECTED:
      const pya = state.paymentAccounts.find((val) => val.href === action.value);
      return { ...state, selectedPaymentAccount: pya };
    case actions.PYA_REMOVED:
      const pyas = state.paymentAccounts.filter((val) => val.href !== action.value);
      return { ...state, paymentAccounts: pyas };
    case actions.AUTH_HTML:
      return { ...state, authHtml: action.value };
    case actions.PY_LOADED:
      return { ...state, payment: action.value };

    default:
      console.warn(`Store received invalid action type: ${action.type}`);
      return state;
  }
};

export { initialState, reducer };
