import { Box, Text, Button } from '@island.is/ui';
import { useContext, useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { PaymentFlow } from '../components/PaymentFlow/PaymentFlow';
import { store } from '../store/store';
import { goToReturnUrl } from '../utils';
import { strings } from '../strings';

function Error() {
  const {
    state: { global: state },
  } = useContext(store);
  const [inProgress] = useState(false);
  const history = useHistory();
  const location = useLocation().search;
  const query = new URLSearchParams(location);
  const errCode = query.get('code');

  // Get error key from query string or state.
  let key = errCode || state.error;
  const { heading, detail } = strings.get(key);

  console.error(`Handling error (error key: '${key}')`);

  const tryAgain = () => {
    // Re-enter.
    history.push('/pay?r=1');
    return null;
  };

  return (
    <>
      <PaymentFlow
        heading=''
        subheading=''
        renderButtons={
          <Box textAlign='center' paddingY={2}>
            <Box paddingY={2}>
              <Button fluid onClick={tryAgain} loading={inProgress}>
                {strings.BACK}
              </Button>
            </Box>
            <Box textAlign='center' paddingY={2}>
              <Button fluid variant='text' onClick={goToReturnUrl}>
                {strings.CANCEL}
              </Button>
            </Box>
          </Box>
        }
      >
        <Box display='flex' justifyContent='center' alignItems='center' flexDirection='column'>
          <Box display='flex' marginBottom={2}>
            <Text as='h3' variant='h3'>
              {heading}
            </Text>
          </Box>
          <Box display='flex' marginBottom={2}>
            <Text>{detail}</Text>
          </Box>
          <Box display='flex' marginBottom={2}>
            <img alt='Payment failed' src={process.env.PUBLIC_URL + '/img/payment-failed.png'} />
          </Box>
        </Box>
      </PaymentFlow>
    </>
  );
}

export default Error;
