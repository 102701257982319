import { HttpService } from './HttpService';
import { env } from '../../environment';

class PaymentApi extends HttpService {
  async pay(payment) {
    const pya = payment.paymentAcct;
    const uri = pya.href ? env.api.pysc : env.api.pync;
    return this.doPost(`${uri}`, payment).then(
      (response) => {
        return response.data;
      },
      (error) => {
        console.error('Payment create failed', error);
        throw error;
      }
    );
  }

  async updateRecord(altKey) {
    const response = await this.doPost(`${env.api.pyur}`, { alternateKey: altKey });
    return response.data;
  }

  async get(href) {
    const expand = `["doc","typ"]`;
    return this.doGet(`${env.api.py}${href}`, expand).then(
      (response) => {
        return response.data;
      },
      (error) => {
        console.error('Payment get error', error);
        throw error;
      }
    );
  }

  async getByAlternateKey(alternateKey: string) {
    const expand = `["doc","typ"]`;
    const rql = `eq(alternateKey,"${alternateKey}")`;
    return this.doFind(env.api.py, rql, expand).then(
      (response) => {
        const results = response.data;
        if (results && results.length > 0) {
          return results[0];
        }
        throw new Error('Payment not found');
      },
      (error) => {
        console.error('Payment find error', error);
        throw error;
      }
    );
  }

  async feeCalc(payment) {
    return this.doPost(`${env.api.feeCalc}`, payment).then(
      (response) => {
        return response.data;
      },
      (error) => {
        console.error('Fee calc failed', error);
        throw error;
      }
    );
  }
}

export const paymentApi = new PaymentApi();
