export function VisaIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 52 29"
      width={52}
    >
      <rect width={52} height={29} fill="#0044B3" rx={4} />
    </svg>
  );
}
