import { HttpService } from './HttpService';
import { env } from '../../environment';

const uri = env.api.doc;

class DocumentApi extends HttpService {
  async get(docId: string) {
    return this.doGet(`${uri}/${docId}`).then(
      (response) => {
        return response.data;
      },
      (error) => {
        console.error('Document get error', error);
        throw error;
      }
    );
  }
}

export const documentApi = new DocumentApi();
