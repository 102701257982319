import { useEffect } from 'react';
import { PaymentFlow } from '../components/PaymentFlow/PaymentFlow';
import { useAuthService } from '../services/AuthService';
import { strings } from '../strings';
import { useLocation } from 'react-router-dom';

function Authenticate() {
  const { initOidc, initAppState } = useAuthService();
  const location = useLocation().search;
  const query = new URLSearchParams(location);
  const retry = query.get('r');

  useEffect(
    () => {
      if (initOidc(retry)) {
        initAppState(retry);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [] /* Only run on first render */
  );

  return <PaymentFlow loading={true} heading={strings.AUTHENTICATING} />;
}

export default Authenticate;
