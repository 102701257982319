import { useCallback, useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Box, Button, Input, Checkbox } from '@island.is/ui';
import luhn from 'luhn';
import { PayHeader, PaymentFlow } from '../components/PaymentFlow/PaymentFlow';
import { currencyAmount } from '../components/CurrencyAmount';
import { usePaymentService } from '../services/PaymentService';
import { store } from '../store/store';
import { goToReturnUrl } from '../utils';
import { env } from '../environment';
import { strings } from '../strings';

function NewCard() {
  const { makePayment } = usePaymentService();
  const history = useHistory();
  const {
    state: { global: state },
  } = useContext(store);
  const [formValid, setFormValid] = useState(false);
  const [inProgress, setInProgress] = useState(false);
  const [name, setName] = useState('');
  const [number, setNumber] = useState('');
  const [cvc, setCvc] = useState('');
  const [expiry, setExpiry] = useState('');
  const [save, setSave] = useState(false);

  const [validationState, setValidationState] = useState({
    cvcMessage: null,
    numberMessage: null,
    expiryMessage: null,
  });

  const sanitizeCardNumber = (cardNumber) => {
    return cardNumber.replace(/[\s-]/g, '');
  };

  const getCardNetwork = (cardNumber) => {
    const n = sanitizeCardNumber(number);
    return isVisa(n) ? 'visa' : 'mastercard';
  };

  const isVisa = (n) => {
    const regex = /^4[0-9]{12}(?:[0-9]{3})?/gm;
    return regex.test(n);
  };

  const validateNumber = (e) => {
    const n = sanitizeCardNumber(number);
    setValidationState({
      ...validationState,
      numberMessage: n === '' || luhn.validate(n) ? null : strings.INVALID_CARD_NUMBER,
    });
    validateForm();
  };

  const validateCvc = () => {
    const valid = cvc === '' || /^\d\d\d(\d)?$/gm.test(cvc);
    setValidationState({
      ...validationState,
      cvcMessage: valid ? null : strings.INVALID_CVC,
    });
    validateForm();
  };

  const curYear = parseInt(new Date().getFullYear().toString().substring(2));

  const parseExpiry = (val = '') => {
    if (val === 'NaN') {
      val = '';
    }
    let [monthVal, yearVal] = val.split('/', 2);
    if (monthVal && monthVal.length > 2) {
      yearVal = monthVal.substring(2);
      monthVal = monthVal.substring(0, 2);
    }
    const priorVal = expiry;
    let [priorMonthVal] = priorVal.split('/', 2);
    // Month is being editted if '/' was just removed or the last keystroke made the month shorter.
    let isEditingMonth =
      priorVal && priorVal.endsWith('/') && val === priorVal.substring(0, priorVal.length - 1);
    if (monthVal !== priorMonthVal && monthVal.length < priorMonthVal.length) {
      isEditingMonth = true;
    }

    let month = parseInt(monthVal);
    let year = parseInt(yearVal);
    if (!isNaN(month)) {
      if (month > 12) {
        // e.g. for "43", assume month is "04" and year starts with "3"
        year = month % 10;
        month = Math.floor(month / 10);
        isEditingMonth = false;
      } else if (monthVal === '1' && val.indexOf('/') < 0) {
        // Month is potentially two digits.
        isEditingMonth = true;
      }
    }
    return {
      rawText: val,
      monthText: monthVal,
      yearText: yearVal,
      month: month,
      year: year,
      isEditingMonth: isEditingMonth,
    };
  };

  const expiryOnChange = (val, force = false) => {
    let { month, year, isEditingMonth } = parseExpiry(val);
    let hasMonth = !isNaN(month);
    let hasYear = !isNaN(year);

    let formattedExpiry = '';
    if (!hasMonth) {
      formattedExpiry = '';
    } else if (!hasYear && (month === 0 || isEditingMonth)) {
      formattedExpiry = `${month}`;
    } else if (hasMonth) {
      formattedExpiry = `${getMonthString(month, !isEditingMonth)}/${year || ''}`;
    }
    setExpiry(formattedExpiry);
    if (force || (hasMonth && hasYear && year >= 10)) {
      doValidateExpiry(month, year);
    } else {
      setValidationState({
        ...validationState,
        expiryMessage: null,
      });
    }
  };

  const getMonthString = (month, pad) => {
    if (pad) {
      return month < 10 ? '0' + month : month;
    } else {
      return month;
    }
  };

  const validateExpiry = () => {
    expiryOnChange(expiry, true);
  }

  const  doValidateExpiry = (month, year) => {
    const validYear = year >= curYear && year <= 99;
    let validMonth = month >= 1 && month <= 12;
    if (year === curYear) {
      const curMonth = new Date().getMonth() + 1;
      validMonth = month >= curMonth;
    }
    setValidationState({
      ...validationState,
      expiryMessage: expiry === '' || (validMonth && validYear) ? null : strings.INVALID_EXPIRY,
    });
    validateForm();
  };

  const validateForm = useCallback(() => {
    const { numberMessage, cvcMessage, expiryMessage } = validationState;
    const hasValues = name !== '' && number !== '' && cvc !== '' && expiry !== '';
    const isValid = hasValues && !numberMessage && !cvcMessage && !expiryMessage;
    setFormValid(isValid);
    return isValid;
  }, [cvc, expiry, name, number, validationState]);

  useEffect(() => {
    validateForm();
  }, [expiry, validateForm]);

  const buildPaymentAccount = () => {
    let { month, year } = parseExpiry(expiry);
    return {
      acctName: name,
      acctNumber: sanitizeCardNumber(number),
      acctType: 'credit',
      cardNetwork: getCardNetwork(number),
      cardVerificationValue: cvc,
      expiryMonth: month,
      expiryYear: 2000 + year,
      isSingleUse: !save,
      state: 'active',
      paymentAcctOwner: {
        loginAcct: {
          href: state.loginAccount.href,
        },
      },
      paymentMethod: {
        alternateKey: 'credit',
      },
    };
  };

  const handleAddClick = () => {
    setInProgress(true);
    makePayment(buildPaymentAccount());
  };

  const handleCancelClick = () => {
    if (state.paymentAccounts.length === 0) {
      // No existing cards, so "cancel" means go back to the return url.
      goToReturnUrl();
    } else {
      history.push('/card');
    }
  };

  return (
    <>
      <PaymentFlow
        heading={<PayHeader docType={state.document?.documentType?.description} />}
        subheading={currencyAmount(state.payment.amount)}
        loading={inProgress}
        renderButtons={
          <>
            <Box paddingY={2}>
              <Button fluid disabled={!formValid} loading={inProgress} onClick={handleAddClick}>
                {strings.PAY}
              </Button>
            </Box>
            <Box textAlign='center' paddingY={2}>
              <Button fluid variant='text' onClick={handleCancelClick}>
                {strings.CANCEL}
              </Button>
            </Box>
          </>
        }
      >
        <Box marginBottom={2}>
          <Input
            size='sm'
            label={strings.NAME}
            placeholder={strings.NAME_PLACEHOLDER}
            required={false}
            name='nafn'
            maxLength={124}
            onChange={(event) => setName(event.target.value)}
            onBlur={validateForm}
          />
        </Box>
        <Box marginBottom={2}>
          <Input
            type='number'
            inputMode='numeric'
            size='sm'
            label={strings.CARD_NUMBER}
            placeholder={strings.CARD_NUMBER_PLACEHOLDER}
            name='kortanumer'
            required={false}
            maxLength={19}
            defaultValue={env.defaultCardNumber}
            hasError={validationState.numberMessage}
            errorMessage={validationState.numberMessage}
            onChange={(event) => setNumber(event.target.value)}
            onBlur={validateNumber}
          />
        </Box>
        <Box marginBottom={[4, 4, 8]}>
          <Box display='flex' marginBottom={2}>
            <Box marginRight={1}>
              <Input
                inputMode='numeric'
                size='sm'
                label={strings.EXPIRY}
                value={expiry}
                placeholder='MM/ÁÁ'
                name='gildistimi'
                maxLength={5}
                hasError={validationState.expiryMessage}
                errorMessage={validationState.expiryMessage}
                onChange={(e) => expiryOnChange(e.target.value)}
                onBlur={validateExpiry}
              />
            </Box>
            <Box marginLeft={1}>
              <Input
                // value={cvc}
                type='number'
                inputMode='numeric'
                size='sm'
                label={strings.CVC}
                placeholder={strings.CVC_PLACEHOLDER}
                name='cvc'
                required={false}
                hasError={validationState.cvcMessage}
                errorMessage={validationState.cvcMessage}
                maxLength={4}
                onChange={(e) => setCvc(e.target.value)}
                onBlur={validateCvc}
              />
            </Box>
          </Box>
          <Checkbox
            label={strings.SAVE_CARD}
            name='vista'
            checked={save}
            onChange={() => setSave(!save)}
          />
        </Box>
      </PaymentFlow>
    </>
  );
}

export default NewCard;
