const DEFAULT_ERROR_KEY = 'DEFAULT_ERROR';
const DETAIL_SUFFIX_KEY = '_DETAIL';

export const strings = {
  // Common
  CANCEL: 'Hætta við',
  PAY: 'Greiða',
  BACK: 'Áfram',

  MASTERCARD: 'Mastercard',
  VISA: 'Visa',

  AUTHENTICATING: 'Auðkenning...',

  PAYMENT_FOR: 'Til greiðslu vegna: ',

  ADD_CARD: 'Bæta við korti',
  REMOVE_CARD: 'Fjarlægja kort?',
  REMOVE_CARD_CONFIRM: 'Staðfesta',
  REMOVE_CARD_CANCEL: 'Hætta við',

  SAVE_CARD: 'Vista greiðslukort',
  EXPIRY: 'Gildistími',
  NAME: 'Nafn korthafa',
  NAME_PLACEHOLDER: 'Nafn korthafa',
  CVC: 'CVC',
  CVC_PLACEHOLDER: 'Öryggiskóði',
  CARD_NUMBER: 'Kortanúmer',
  CARD_NUMBER_PLACEHOLDER: 'Kortanúmer',

  DOC_TYPE: 'Skýring',
  AUTHORIZATION: 'Heimild',
  PAYMENT_TIME: 'Greiðslutími',
  CONFIRMATION_NUMBER: 'Færslunúmer',

  PROCESSING_PAYMENT: 'Framkvæmi greiðslu...',
  PAYMENT_SUCCESSFUL: 'Greiðsla tókst',
  PAYMENT_FAILED: 'Ekki heimild',
  PAYMENT_FAILED_DETAIL: 'Reyndu aftur eða hafðu samband við bankann þinn',

  INVALID_EXPIRY: 'Rangur gildistími',
  INVALID_CARD_NUMBER: 'Rangt kortenúmer',
  INVALID_CVC: 'Rangt CVC númer',

  // Errors
  DEFAULT_ERROR: 'Ekki heimild',
  DEFAULT_ERROR_DETAIL: 'Reyndu aftur eða hafðu samband við bankann þinn',

  AUTH_FAILED: 'Auðkenning mistókst',
  AUTH_DENIED: 'Ekki heimilað',
  AUTH_DENIED_DETAIL: 'Þú hefur ekki aðgang að þessu skjali.',

  DOC_NOT_SPECIFIED: 'Skjal ekki skilgreint',
  DOC_NOT_FOUND: 'Villa er reynt er að sækja skjal',
  DOC_NOT_PAYABLE: "Ekki greiðanlegt skjal",
  DOC_ZERO_OWING: 'Skjal inniheldur ekki upphæð til greiðslu',
  DOC_PAID: 'Reikningur hefur verið greiddur',

  /**
   * Returns an object with resolved error heading and detail text.
   * @param {*} key
   * @returns
   */
  get: (key) => {
    key = key || DEFAULT_ERROR_KEY;
    return {
      heading: strings[key] || strings[DEFAULT_ERROR_KEY],
      detail: strings[key + DETAIL_SUFFIX_KEY] || strings[DEFAULT_ERROR_KEY + DETAIL_SUFFIX_KEY],
    };
  },
};
